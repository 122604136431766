<template>
    <div class="chat-item">
        <div class="image" :style="`background-image: url('${chatImage}')`"></div>
        <div class="chat-content">
            <div class="name">
                <div class="driverName text-overflow">{{ item.name }} {{ item.lastname }}</div>
                <span>{{ item.carrier.companyName }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['item'],
        computed: {
            chatImage() {
                if(this.item.image) return this.item.image;
                return this.DEFAULTS.USER.IMAGE;
            }
        }
    }
</script>

<style lang="scss" scoped>
.chat-item {
    padding: 10px;
    display: grid;
    grid-template-columns: 44px minmax(0, 1fr);
    gap: 15px;
    border-bottom: 1px solid $borderColor;
    transition: ease 0.1s;
    cursor: pointer;
    user-select: none;
    &:hover {
        background: rgba(0,0,0,0.05);
    }
}

.image {
    width: 44px;
    height: 44px;
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.chat-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .seenIcon {
        position: absolute;
        top: 0;
        right: 0;
        color: $themeColor2;
        font-size: 12px;
    }
}

.driverName {
    font-weight: 500;
    font-size: 14px;
}

.carrierName {
    font-size: 12px;
}

.message {
    display: grid;
    align-items: start;
    grid-template-columns: auto minmax(0, 1fr);
    gap: 5px;
    font-size: 12px;
}

.unreadCount {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $themeColor2;
    padding: 2px 7px;
    font-size: 10px;
    color: $themeColor1;
    border-radius: 24px;
    line-height: 12px;
}
</style>