import { defineStore } from 'pinia'

const REFERRALS_DEFAULT = {
    stats: {
        paid: { total: 0 },
        outstanding: { total: 0 }
    }
}

export const useReferralsStore = defineStore('referrals', {
    state: () => ({
        user: null,
        token: null,
        stats: {
            paid: { total: 0 },
            outstanding: { total: 0 }
        },
        carriers: [],
    }),
    getters: {
        isLoggedIn: (state) => {
            return state.user && state.token
        },
    },
    actions: {
        login(token, user) {
            this.user = user;
            this.token = token;
        },
        logout() {
            localStorage.removeItem('referral-token')
            this.user = null;
            this.token = null;
            this.carriers = [];
            this.stats = REFERRALS_DEFAULT.stats;
        },
        setCarriers(carriers) {
            this.carriers = carriers;
        },
        setStats(stats) {
            this.stats = stats;
        }
    },
})