<template>
  <div class="mapHolder" :style="`padding-top: ${userHeight}`">
    <div class="map">
      <LMap
        :zoom="5"
        @ready="mapReady"
        :center="[39.781156478647155, -101.40170000039568]"
        @update:zoom="zoomUpdated"
      >
        <l-tile-layer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <slot></slot>
      </LMap>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
  },
  props: ["height"],
  data() {
    return {
      _map: null,
      userHeight: this.height || "75%",
      zoom: 5,
    };
  },
  methods: {
    mapReady(map) {
      this._map = map;
      this.$emit("ready", this._map);
      this.$emit("zoom", this.zoom);
    },
    fitBounds(bounds) {
      this._map.fitBounds(bounds);
    },
    setView(latLng, zoom = 10) {
      this._map.setView(latLng, zoom);
    },
    panTo(latLng) {
      this._map.panTo(latLng);
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
      this.$emit("zoom", this.zoom);
    },
  },
};
</script>

<style lang="scss" scoped>
.mapHolder {
  width: 100%;
  padding-top: 75%;
  position: relative;
  z-index: 0;
  .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
