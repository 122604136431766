import { useReferralsStore } from '../store/Referrals'
import { pinia } from '../main'
import axios from 'axios';

export default {
    path: '/referral',
    name: 'ReferralApp',
    component: function () { return import('../views/ReferralApp/App.vue') },
    beforeEnter: async (to, from) => {

        let referralToken = localStorage.getItem('referral-token');
        if (!referralToken) return;

        const referralStore = useReferralsStore(pinia);

        const user = await new Promise(resolve => {
            axios.get('/referrals/user')
                .then(data => {
                    if (data.data && data.data._id) {
                        resolve(data.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch(err => {
                    resolve(null);
                });
        });

        if (user) {
            referralStore.login(referralToken, user);
        } else {
            localStorage.removeItem('referral-token');
        }

    },
    children: [
        {
            path: '',
            name: 'ReferralAppMain',
            component: function () { return import('../views/ReferralApp/Main.vue') },
        },
        {
            path: 'carriers',
            name: 'ReferralAppCarriers',
            component: function () { return import('../views/ReferralApp/Carriers.vue') },
        },
        {
            path: 'payments',
            name: 'ReferralAppPayments',
            component: function () { return import('../views/ReferralApp/Payments.vue') },
        }
    ]
}