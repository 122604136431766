<template>
    <div class="input">
        <div class="_header">
            <label class="label">{{ name || 'Label' }}</label>
            <span class="_error">{{ error }}</span>
        </div>
        <DropDownCheckbox class="input-field dropdown-input" :placeholder="placeholder" v-model="content" :showAttribute="showAttribute" :selectAttribute="selectAttribute" :options="options"/>
        <div class="description" v-if="description">{{ description }}</div>
    </div>
</template>

<script>
import DropDownCheckbox from './DropDownCheckbox.vue';


    export default {
    props: ["name", "modelValue", "error", "placeholder", "description", "showAttribute", "selectAttribute", "options"],
    data() {
        return {
            content: this.modelValue
        };
    },
    methods: {},
    watch: {
        content(newValue, oldValue) {
            this.$emit("update:modelValue", this.content);
        },
        modelValue() {
            this.content = this.modelValue;
        }
    },
    components: { DropDownCheckbox }
}
</script>


<style lang="scss" scoped>
.dropdown-input {
    padding: 0 10px;
    max-width: 100%;
}
</style>