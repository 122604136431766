<template>
    <LPolyline v-if="coordinates" :lat-lngs="coordinates" @ready="polylineLoaded"/>
    <LMarker :lat-lng="marker.coordinates" :key="key" v-for="(marker, key) of markers">
        <LPopup :content="marker.cityState"></LPopup>
    </LMarker>
</template>

<script>
import { LMarker, LPolyline, LPopup } from "@vue-leaflet/vue-leaflet";
import L from 'leaflet';
import 'polyline-encoded'

import OSRM from 'osrm-client-js'
var osrm = new OSRM("https://router.project-osrm.org");

    export default {
        components: {
            LPolyline,
            LMarker,
            LPopup
        },
        props: ['stops'],
        emits: ['ready'],
        data() {
            return {
                coordinates: null,
                markers: []
            }
        },
        watch: {
            stops: {
                deep: true,
                handler() {
                    this.getDirectionsForStops();
                }
            }
        },
        methods: {
            polylineLoaded(polyline) {
                this.$emit('ready', polyline);
            },
            async getDirectionsForStops() {
                try {

                    let locations = [];
                    let markers = [];

                    for(const stop of this.stops) {
                        let marker = {
                            coordinates: [stop.place.lat, stop.place.lng],
                            cityState: stop.place.cityState
                        }
                        markers.push(marker);
                        locations.push([stop.place.lng, stop.place.lat]);
                    }

                    this.markers = markers;

                    let route = await new Promise(resolve => {
                        osrm.route({
                            coordinates: locations,
                            alternatives: false,
                            geometries: 'polyline',
                            steps: true
                        }, function(err, result) {
                            try {
                                const latLngs = L.Polyline.fromEncoded(result.routes[0].geometry).getLatLngs();
                                resolve(latLngs);
                            } catch(e) {
                                resolve(null)
                            }
                        });
                    });

                    this.coordinates = route;
                } catch(e) {
                    this.coordinates = null;
                }
            }
        },
        mounted() {
            this.getDirectionsForStops();
        }
    }
</script>

<style lang="scss" scoped>

</style>