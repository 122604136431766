import {
    createRouter,
    createWebHistory
} from 'vue-router'

import axios from 'axios';
import store from '../store';

const routes = [];


// user protected routes

import DispatchRoutes from './dispatch-routes'
import adminRoutes from './admin-routes'

import referralRoutes from './referral-routes'


let mainAppRoute = { path: '/', name: 'App', component: function () { return import('../views/Main.vue') }, children: [], meta: { requiresAuth: true } };
mainAppRoute.children.push({ path: '/', name: 'Dashboard', component: function () { return import('../views/Dashboard.vue') } });
mainAppRoute.children.push(DispatchRoutes);
mainAppRoute.children.push(adminRoutes);
mainAppRoute.children.push({ path: '/salary', name: 'Salary', component: function () { return import('../views/User/Salary.vue') } });
mainAppRoute.children.push({ path: '/salary/:id', name: 'SalaryReportById', component: function () { return import('../views/User/SalaryReport.vue') } });
routes.push(mainAppRoute);

// other routes without login
// user invitation page
routes.push({ path: '/user/invitation/:id', name: 'UserInvitation', component: function () { return import('../views/User/Invitation.vue') } });

// view invoice details here
routes.push({ path: '/invoice/:id', name: 'Invoice', component: function () { return import('../views/Invoice.vue') } });

routes.push(referralRoutes);

// page not found
routes.push({ path: '/:pathMatch(.*)*', name: 'PageNotFound', component: function () { return import('../views/PageNotFound.vue') } });


// create router
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// before each route
router.beforeEach(async (to, from, next) => {

    if(to.path === from.path && from.name) {
        return next();
    }

    let token = localStorage.getItem('token');
    if(to.meta && to.meta.requiresAuth) {

        if(!token) return next();
        store.commit('token', token);

        await axios.get('/user')
        .then(data => {
            store.commit('user', data.data);
        })
        .catch(err => {
            store.commit('userLogout');
        });

        // check here if user is allowed to visit following page
        if(to.meta.permissions && store.state.user) {
            let permissions = to.meta.permissions;
            let user = store.state.user;

            let permitted = false;
            for(const item of permissions) {
                let isPermitted = user.isAuthorized(item.name, item.action);
                if(isPermitted) permitted = true;
            }
            if(!permitted) {
                try {
                    if(from.name) return next({ name: from.name }); 
                    else if(from.path && from.path != to.path) return next({ path: from.path });
                    throw new Error();
                } catch(e) {
                    return next({ path: '/' });
                }
            }
        }

    }

    return next();
});
  
export default router