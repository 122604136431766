<template>
    <div class="message" @click="closeMessage" @mouseenter="startTimeout">
        {{ message.message }}
    </div>
</template>

<script>
    export default {
        props: ['message'],
        data() {
            return {
                timeout: null
            }
        },
        methods: {
            startTimeout() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.closeMessage();
                }, 5000);
            },
            closeMessage() {
                clearTimeout(this.timeout);
                this.$emit('hideMessage');
            }
        },
        mounted() {
            this.startTimeout();
        }
    }
</script>

<style lang="scss" scoped>
.message {
    padding: 5px 10px;
    background: rgba(0,0,0,0.8);
    border-radius: 9px;
    border: 1px solid rgba(0,0,0,0.1);
    color: rgba(255,255,255,0.85);
    cursor: pointer;
}
</style>