<template>
    <div class="dropdown-select" @click="switchView" v-click-outside="hideView" :class="{ 'active' : shown }">
        <div class="selection text-overflow">{{ selected }}</div>
        <div class="icon-holder">
            <span v-if="shown"><i class="fa-solid fa-chevron-up"></i></span>
            <span v-else><i class="fa-solid fa-chevron-down"></i></span>
        </div>
        <div class="list-window-holder" :class="[ `position-${ position || 'left' }`, shown ? 'active' : '' ]"  @click.stop>
            <div class="list-window" :class="[ shown ? 'active' : loaded ? 'hidden' : '' ]">
                <div class="list-input-holder" v-if="(userOptions.length >= 6)">
                    <input type="text" class="list-search" v-model="search" placeholder="Search">
                </div>

                <div class="list-scroll">
                    <div class="list-content">

                        <div class="list" v-if="(searchedUserOptions.length > 0)">
                            <div class="option" :key="key" v-for="(opt, key) of searchedUserOptions">
                                <Checkbox v-model="selection" :val="opt[selectAttribute]"/>
                                <div class="name ">{{ opt[showAttribute] }}</div>
                            </div>
                        </div>

                        <div class="list" v-else>
                            <NoDataMessage style="padding: 0;" text="Try again..."/>
                        </div>

                    </div>
                </div>

                <div class="list-button-holder">
                    <Button text="Apply" icon="fa-solid fa-check" size="small" @click="applyChanges(); hideView();"/>
                    <div class="clear-button"><a href="" @click.prevent="clearSelection">Clear</a></div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            options: { type: Array },
            showAttribute: { type: String, required: true },
            selectAttribute: { type: String, required: true },
            placeholder: { type: String, required: false },
            modelValue: { required: true },
            position: { type: String },
        },
        data() {
            return {
                shown: false,
                selection: [],
                search: '',
                loaded: false
            }
        },
        mounted() {
            this.validateModelValue();
        },
        methods: {
            hideView() {
                this.shown = false;
            },
            switchView() {
                this.shown = !this.shown;
                if(this.shown && !this.loaded) {
                    this.loaded = true;
                }
            },
            validateModelValue() {
                try {
                    if(Array.isArray(this.modelValue)) {
                        this.selection = this.modelValue;
                    } else {
                        throw new Error();
                    }
                } catch(e) {
                    this.selection = [];
                    this.applyChanges();
                }
            },
            applyChanges() {
                this.$emit('update:modelValue', this.selection);
            },
            clearSelection() {
                this.selection = [];
                this.applyChanges();
            }
        },
        computed: {
            selected() {
                let array = [];
                
                if(Array.isArray(this.modelValue)) {
                    for(const item of this.modelValue) {
                        for(const opt of this.userOptions) {
                            if(opt[this.selectAttribute] === item) array.push(opt[this.showAttribute])
                        }
                    }
                }

                if(array.length > 0) {
                    let name = array[0];
                    if(array.length > 1) {
                        let restCount = array.length - 1;
                        name += ` & ${restCount} more`
                    }
                    return name;
                }

                return this.placeholder || 'Select';
            },
            userOptions() {
                if(!this.options) return [];
                if(!Array.isArray(this.options)) return [];
                return this.options.filter(item => {
                    if(item.hasOwnProperty(this.selectAttribute) && item.hasOwnProperty(this.showAttribute)) return true;
                    return false;
                });
            },
            searchedUserOptions() {
                let search = this.search.toLowerCase();
                return this.userOptions.filter(item => {
                    if(typeof item[this.showAttribute] !== 'string') return false;
                    let name = item[this.showAttribute].toLowerCase();
                    if(name.includes(search) || search.includes(name)) return true;
                    return false;
                });
            }
        },
        watch: {
            modelValue() {
                this.validateModelValue();
            }
        }
    }
</script>

<style lang="scss" scoped>


@keyframes popUpAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes popUpAnimationReverse {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}


.dropdown-select {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 14px;
    column-gap: 15px;
    max-width: 220px;
    align-items: center;
    position: relative;
    padding: 0;
    user-select: none;
    cursor: pointer;
    
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 3px;
        width: 100%;
        border-bottom: 2px solid $bgShade;
        transition: ease 0.3s;
    }
    &:hover {
        &::after {
            border-bottom: 2px solid $themeColor2;
        }
    }
    &.active {
        z-index: 1;
        &::after {
            border-bottom: 2px solid $themeColor2;
        }
    }

    .list-search {
        width: 100%;
        height: 44px;
        border-radius: 0;
        border: 0;
        padding: 0 20px;
        font-size: 12px;
        outline: none;
    }

    .icon-holder {
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .list-input-holder {
        border-bottom: 1px solid $borderColor;
    }

    .list-button-holder {
        padding: 20px 20px 10px 20px;
        border-top: 1px solid $borderColor;
        display: grid;
        row-gap: 10px;
        .clear-button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button {
            width: 100%;
        }
    }



    .list-window-holder {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateY(20px);
        cursor: auto;
        user-select: text;
        pointer-events: none;
        &.active {
            pointer-events: auto;
        }
        &.position-center {
            left: 50%;
            transform: translate(-50%, 20px);
            right: auto;
        }
        &.position-right {
            left: auto;
            right: 0;
            transform: translateY(20px);
        }
    }

    .list-window {
        min-width: 220px;
        max-width: 350px;
        background: #fff;
        box-shadow: $boxShadow;
        border-radius: 10px;
        display: grid;
        border: 1px solid $borderColor;
        overflow: hidden;
        opacity: 0;
        transform-origin: 50% 0;
        transform: scale(0);
        pointer-events: none;
        transition: ease 0.1s;
        
        &.hidden {
            pointer-events: none;
            animation: popUpAnimationReverse 0.15s;
            animation-fill-mode: forwards;
        }
        &.active {
            pointer-events: auto;
            animation: popUpAnimation 0.2s;
            animation-fill-mode: forwards;
        }
        .list-scroll {
            overflow: auto;
            max-height: 200px;
        }
        .list-content {
            padding: 20px;
            display: grid;
            row-gap: 20px;
            .list {
                display: grid;
                row-gap: 5px;
            }
        }
    }

    .option {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        column-gap: 10px;
        align-items: center;
    }

}

</style>