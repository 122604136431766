<template>
    <div class="chat-thread-holder">
        <div class="chat-thread-scroll">
            <DynamicScroller class="messages-grid"  @resize="scrollToStart" ref="messagesList" :items="items" :min-item-size="24" @update="onUpdateStart" :emitUpdate="true" keyField="_id">
                <template v-slot="{ item, active }">
                    <DynamicScrollerItem :item="item" :active="active" :data-index="item._id">
                        <Message :message="item"/>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>
        </div>
        <div class="send-message-group">
            <resize-textarea ref="textarea" :key="messageUpdateDate" class="message-area" placeholder="Message" :rows="1" :minHeight="20" :maxHeight="150" v-model="message" @keydown.enter="sendMessage"></resize-textarea>
        </div>
    </div>
</template>

<script>
import Message from './Message.vue';

import { DynamicScrollerItem } from 'vue-virtual-scroller';

    export default {
        components: { Message, DynamicScrollerItem },
        props: ["messages", "chatId"],
        data() {
            return {
                scrollOffset: 0,
                messagesList: {},
                message: '',
                messageUpdateDate: new Date()
            };
        },
        watch: {
            messages() {
                this.messagesList = this.messages;
            },
            "messagesList.messages"() {
                this.scrollToStart();
                this.markMessagesAsSeen();
            },
            messageUpdateDate() {
                setTimeout(() => {
                    this.$refs['textarea'].$el.focus();
                }, 100);
            }
        },
        computed: {
            items() {
                return this.messagesList.messages || [];
            }
        },
        methods: {
            getScroll() {
                let list = this.$refs["messagesList"].$el;
                let val = (list.scrollHeight - list.clientHeight) - list.scrollTop;
                this.scrollOffset = Math.max(val, 0);
            },
            scrollToStart() {
                this.$refs.messagesList.scrollToBottom();
            },
            onScrollEvent(ev) {
                this.getScroll();
            },
            onUpdateStart() {
                if(this.rendered) return;
                this.rendered = true;
                this.scrollToStart();
            },
            async sendMessage(event) {
                if (event.shiftKey) {
                    return;
                }

                event.preventDefault();

                let trimmedMessage = this.message.trim();
                if (trimmedMessage.length <= 0) return;
                if(this.isLoading(['Chat_SendMessage'])) return;
                
                await this.ajax('Chat_SendMessage', {
                    method: 'POST',
                    url: `/chats/${this.chatId}`,
                    data: {
                        message: this.message
                    }
                }, (err, body) => {
                    if(!err) {
                        try {
                            let audio = new Audio('/sound/pop.wav');
                            audio.play();
                        } catch(e) {}
                        
                        this.message = '';
                        this.messageUpdateDate = new Date();
                        this.$emit('messageSent', { chatId: this.chatId, message: { ...body, chatId: this.chatId }, offset: -1 });
                    }
                })
            },
            markMessagesAsSeen() {
                this.ajax('Chat_MarkAsSeen', {
                    method: 'PUT',
                    url: `/chats/${this.chatId}/markAsSeen`,
                }, (err, body) => {})
            }
        }, 
        beforeUnmount() {
            let list = this.$refs["messagesList"].$el;
            list.removeEventListener("scroll", this.onScrollEvent);
        },
        mounted() {
            this.messagesList = this.messages;
            let list = this.$refs["messagesList"].$el;
            list.addEventListener("scroll", this.onScrollEvent);
            setTimeout(this.scrollToStart, 100);
        },
    }
</script>

<style lang="scss" scoped>
.chat-thread-holder {
    height: 450px;
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
}

.chat-thread-scroll {
    height: 100%;
}

.messages-grid {
    height: 100%;
    padding: 15px 10px;
}

.message-area {
    width: 100%;
    max-height: 120px;
    height: 28px;
    resize: none;
    border-radius: 9px;
    border: 1px solid $borderColor;
    outline: none;
    margin: 0;
}

.send-message-group {
    padding: 10px;
    border-top: 1px solid $borderColor;
    background: $bg;
}
</style>