<template>
    <div class="toaster-group">
        
        <TransitionGroup name="fade">
            <ToastMessage :key="message.id" @hideMessage="hideMessage(message.id)" v-for="message of messages" :message="message"/>
        </TransitionGroup>
        
    </div>
</template>

<script>
import ToastMessage from './ToastMessage.vue';
import { v4 as uuidv4 } from 'uuid'

    export default {
        components: {
            ToastMessage
        },
        data() {
            return {
                messages: []
            }
        },
        methods: {
            hideMessage(id) {
                const indexToRemove = this.messages.findIndex(obj => obj.id === id);
                if (indexToRemove !== -1) {
                    this.messages.splice(indexToRemove, 1);
                }
            },
            showAlert(message = '') {
                // this.messages = [];
                this.messages.push({
                    id: uuidv4(),
                    message: message
                });

            }
        },
    }
</script>

<style lang="scss" scoped>
.toaster-group {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 220px;
    width: 100%;
    z-index: 99999999999999;
    display: grid;
    gap: 5px;
    justify-items: center;
}


/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from {
    opacity: 0;
    transform: translate(30px, 0);
}

.fade-leave-to {
  opacity: 0;
  transform: translate(0, -10px);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}

</style>