<template>
    <div class="input">
        <div class="_header">
            <label class="label">{{ name || 'Label' }}</label>
            <span class="_error">{{ error }}</span>
        </div>

        <DropDownDatePicker class="input-field dropdown-input" v-model="content" :mode="mode" :range="isRange" :displayFormat="displayFormat" :placeholder="placeholder" />
        <div class="description" v-if="description">{{ description }}</div>
    </div>
</template>

<script>
    export default {
        props: ['name', 'modelValue', 'error', 'placeholder', 'description', 'range', 'displayFormat', 'mode'],
        data() {
            return {
                content: this.modelValue,
                isRange: this.range ? true : false
            }
        },
        methods: {
            
        },
        watch: {
            content(newValue, oldValue) {
                this.$emit('update:modelValue', this.content);
            },
            modelValue() {
                this.content = this.modelValue;
            }
        },
    }
</script>


<style lang="scss" scoped>
.dropdown-input {
    padding: 0 10px;
    max-width: 100%;
}
</style>