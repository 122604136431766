export default {
  path: "/dispatch",
  name: "Dispatch",
  component: function () {
    return import("../views/Dispatch/Main.vue");
  },
  children: [
    {
      path: "",
      name: "LoadBoard",
      component: function () {
        return import("../views/Dispatch/LoadBoard.vue");
      },
      meta: {
        permissions: [{ name: "Loads" }],
      },
    },
    {
      path: "tracking",
      name: "Tracking",
      component: function () {
        return import("../views/Dispatch/Tracking/MainView.vue");
      },
      meta: {
        permissions: [{ name: "Loads" }],
      },
    },
    {
      path: "loads-board",
      name: "LoadSearch",
      component: function () {
        return import("../views/Dispatch/LoadSearch/LoadSearch.vue");
      },
      meta: {
        permissions: [{ name: "Loads" }],
      },
    },
  ],
};
