<template>
    <div class="dropdown-select" @click="switchView" v-click-outside="hideView" :class="{ 'active' : shown }">
        <div class="selection text-overflow">{{ selected }}</div>
        <div class="icon-holder">
            <span v-if="shown"><i class="fa-solid fa-chevron-up"></i></span>
            <span v-else><i class="fa-solid fa-chevron-down"></i></span>
        </div>
        <div class="list-window-holder" :class="[ `position-${ position || 'left' }`, shown ? 'active' : '' ]"  @click.stop>
            <div class="list-window" :class="[ shown ? 'active' : loaded ? 'hidden' : '' ]">


                <div v-if="!customRangeShown">
                    <div class="options-list">
                        <a href="" class="button-link" :class="{ 'active' : activeOption === null }" @click.prevent="customRangeShown = true">
                            <div>
                                <div class="name">Custom Range</div>
                                <div class="display">{{ selected }}</div>
                            </div>
                            <span class="icon" v-if="activeOption === null"><i class="fa-solid fa-check"></i></span>
                        </a>

                        <a href="" class="button-link" :class="{ 'active' : activeOption === key }" :key="key" v-for="(item, key) of dateOptions" @click.prevent="setSelectionRange(item)">
                            <div>
                                <div class="name">{{ item.name }}</div>
                                <div class="display">{{ item.display }}</div>
                            </div>
                            <span class="icon" v-if="activeOption === key"><i class="fa-solid fa-check"></i></span>
                        </a>
                    </div>

                    <div style="display: flex; justify-content: center; align-items: center; padding: 10px;">
                        <a href="" @click.prevent="setSelectionRange(null)">Clear</a>
                    </div>
                </div>

                <div v-show="customRangeShown">
                    <DatePicker class="date-picker" @dayclick="dayClick" :model-config="modelConfig" :minute-increment="15" trim-weeks v-model="selection" :mode="mode || 'date'" timezone="UTC" @weeknumberclick="weekNumberClicked" :is-range="true" show-iso-weeknumbers/>
                    <div style="display: flex; justify-content: center; align-items: center; padding: 10px;">
                        <a href="" @click.prevent="customRangeShown = false">Back</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
    export default {
        props: {
            placeholder: { type: String, required: false },
            modelValue: { required: true },
            position: { type: String },
            range: { type: Boolean },
            displayFormat: { type: String },
            mode: { type: String },
            weekly: { type: Boolean }
        },
        data() {
            return {
                customRangeShown: false,
                shown: false,
                selection: this.modelValue || null,
                loaded: false,
                isRange: this.range ? true : false,
                _displayFormat: this.displayFormat || "MMM D, YYYY",
                modelConfig: {
                    timeAssign: '12:00:00',
                },
                dateOptions: []
            }
        },
        mounted() {
            this.generateOptions();
        },  
        methods: {
            setSelectionRange(item) {
                if(item) {
                    this.selection = {
                        start: item.start,
                        end: item.end
                    }
                } else {
                    this.selection = null;
                }
            },
            dayClick(e) {

            },
            weekNumberClicked(e) {
                if(!this.range) return;
                let days = e.days;
                let newSelection = {
                    start: days[0].date,
                    end: days[6].date,
                }
                this.selection = newSelection;
            },
            hideView() {
                this.shown = false;
                this.customRangeShown = false;
            },
            switchView() {
                this.shown = !this.shown;
                if(this.shown && !this.loaded) {
                    this.loaded = true;
                }
            },
            applyChanges() {
                this.hideView();
                this.$emit('update:modelValue', this.selection);
            },
            generateOptions() {
                let thisWeek = { start: moment.utc().startOf('isoWeek'), end: moment.utc().endOf('isoWeek') };
                let nextWeek = { start: moment.utc().add(1, 'week').startOf('isoWeek'), end: moment.utc().add(1, 'week').endOf('isoWeek') };
                let lastWeek = { start: moment.utc().subtract(1, 'week').startOf('isoWeek'), end: moment.utc().subtract(1, 'week').endOf('isoWeek') };
                let monthToDate = { start: moment.utc().startOf('month'), end: moment.utc().endOf('day') };
                let thisMonth = { start: moment.utc().startOf('month'), end: moment.utc().endOf('month') };
                let lastMonth = { start: moment.utc().subtract(1, 'month').startOf('month'), end: moment.utc().subtract(1, 'month').endOf('month') };
                let yearToDate = { start: moment.utc().startOf('year'), end: moment.utc().endOf('day') };
                let thisYear = { start: moment.utc().startOf('year'), end: moment.utc().endOf('year') };
                let lastYear = { start: moment.utc().subtract(1, 'year').startOf('year'), end: moment.utc().subtract(1, 'year').endOf('year') };

                let options = [
                    { name: 'Next Week', start: nextWeek.start.toDate(), end: nextWeek.end.toDate(), display: nextWeek.start.format("D MMM") + ' - ' + nextWeek.end.format("D MMM") },
                    { name: 'This Week', start: thisWeek.start.toDate(), end: thisWeek.end.toDate(), display: thisWeek.start.format("D MMM") + ' - ' + thisWeek.end.format("D MMM") },
                    { name: 'Last Week', start: lastWeek.start.toDate(), end: lastWeek.end.toDate(), display: lastWeek.start.format("D MMM") + ' - ' + lastWeek.end.format("D MMM") },
                    { name: 'Month to Date', start: monthToDate.start.toDate(), end: monthToDate.end.toDate(), display: monthToDate.start.format("D MMM, YYYY") + ' - ' + monthToDate.end.format("D MMM, YYYY") },
                    { name: 'This Month', start: thisMonth.start.toDate(), end: thisMonth.end.toDate(), display: thisMonth.start.format("MMMM") },
                    { name: 'Last Month', start: lastMonth.start.toDate(), end: lastMonth.end.toDate(), display: lastMonth.start.format("MMMM") },
                    { name: 'Year to Date', start: yearToDate.start.toDate(), end: yearToDate.end.toDate(), display: yearToDate.start.format("D MMM, YYYY") + ' - ' + yearToDate.end.format("D MMM, YYYY") },
                    { name: 'This Year', start: thisYear.start.toDate(), end: thisYear.end.toDate(), display: thisYear.start.format("YYYY") },
                    { name: 'Last Year', start: lastYear.start.toDate(), end: lastYear.end.toDate(), display: lastYear.start.format("YYYY") },
                ];

                this.dateOptions = options;
            }
        },
        computed: {
            activeOption() {
                try {


                    let startDate = moment.utc(this.selection.start).startOf('day');
                    let endDate = moment.utc(this.selection.end).endOf('day');

                    let selected = null;
                    let i = 0;
                    for (const item of this.dateOptions) {
                        let date1 = moment.utc(item.start).startOf('day');
                        let date2 = moment.utc(item.end).endOf('day');

                        let compare1 = moment.duration(startDate.diff(date1));
                        let compare2 = moment.duration(endDate.diff(date2));

                        if (compare1.asDays() === 0 && compare2.asDays() === 0) {
                            selected = i;
                            break;
                        };
                        i++;
                    }

                    return selected;
                } catch(e) {
                    return null;
                }
            },  
            selected() {
                if(!this.selection) return this.placeholder;
                try {
                    let start = moment.utc(this.selection.start).format(this._displayFormat);
                    let end = moment.utc(this.selection.end).format(this._displayFormat);
                    return start + " - " + end;
                } catch(e) {
                    return this.placeholder;
                }
            },
        },
        watch: {
            modelValue() {
                this.selection = this.modelValue;
            },
            selection(newValue, oldValue) {
                this.applyChanges();
            }
        }
    }
</script>

<style lang="scss">

.vc-day .vc-opacity-0 {
  opacity: 0.5;
}

.vc-day .vc-pointer-events-none {
  pointer-events: all;
}
</style>


<style lang="scss" scoped>

.options-list {
    display: grid;
    gap: 3px;
    padding: 5px;
    a.button-link {
        width: 100%;
        background: transparent;
        border: 0;
        color: rgba(0,0,0,0.5);
        border-radius: 9px;
        padding: 5px 10px;
        cursor: pointer;
        transition: ease 0.3s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .display {
            font-size: 10px;
        }
        .name {
            font-weight: 500;
        }
        &:hover, &.active {
            background: $bg;
            color: $themeColor2;
        }
    }
}


@keyframes popUpAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes popUpAnimationReverse {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}


.dropdown-select {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 14px;
    column-gap: 15px;
    max-width: 220px;
    align-items: center;
    position: relative;
    padding: 0;
    z-index: 1;
    user-select: none;
    cursor: pointer;
    
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 3px;
        width: 100%;
        border-bottom: 2px solid $bgShade;
        transition: ease 0.3s;
    }
    &:hover {
        &::after {
            border-bottom: 2px solid $themeColor2;
        }
    }
    &.active {
        z-index: 2;
        &::after {
            border-bottom: 2px solid $themeColor2;
        }
    }

    .list-search {
        width: 100%;
        height: 44px;
        border-radius: 0;
        border: 0;
        padding: 0 20px;
        font-size: 12px;
        outline: none;
    }

    .icon-holder {
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .list-window-holder {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateY(20px);
        cursor: auto;
        user-select: text;
        pointer-events: none;
        &.active {
            pointer-events: auto;
        }
        &.position-center {
            left: 50%;
            transform: translate(-50%, 20px);
            right: auto;
        }
        &.position-right {
            left: auto;
            right: 0;
            transform: translateY(20px);
        }
    }

    .list-window {
        min-width: 220px;
        max-width: 350px;
        background: #fff;
        box-shadow: $boxShadow;
        border-radius: 10px;
        display: grid;
        border: 1px solid $borderColor;
        overflow: hidden;
        opacity: 0;
        transform-origin: 50% 0;
        transform: scale(0);
        pointer-events: none;
        transition: ease 0.1s;
        
        &.hidden {
            pointer-events: none;
            animation: popUpAnimationReverse 0.15s;
            animation-fill-mode: forwards;
        }
        &.active {
            pointer-events: auto;
            animation: popUpAnimation 0.2s;
            animation-fill-mode: forwards;
        }
       
    }

}

.date-picker {
    border: 0;
    background: transparent;
}

</style>