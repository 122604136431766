module.exports = class {

    constructor() {
        this.events = {};
        this.state = 'pending';
    }

    setUrl(url) {
        this.url = url;
        this.ws = null;
        this.keepAliveInterval = null;
    }

    close() {
        this.state = 'closed';
        if(!this.ws) return;
        this.ws.close();
    }

    connect() {
        if(this.state === 'closed') return;

        setTimeout(() => {

            this.ws = new WebSocket(this.url);
            this.ws.addEventListener('open', () => {
                this.fireEvent('open');
                clearInterval(this.keepAliveInterval);
                this.keepAliveInterval = setInterval(() => {
                    this.send('Keep-Alive', {});
                }, 1000 * 30);
            });
            this.ws.addEventListener('error', () => {

            });
            this.ws.addEventListener('close', () => {
                this.fireEvent('close');
                clearInterval(this.keepAliveInterval);
                this.connect();
            });
            this.ws.addEventListener('message', (message) => {
                if(!message.data) return;
                try {
                    message = JSON.parse(message.data);
                    this.fireEvent(message.action, message.data);
                } catch(e) {

                }
            });
        }, this.ws ? 3000 : 1);
    }

    send(action, body) {
        if(!this.ws) return;
        let message = {
            action: action,
            data: body
        }
        if(this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(message));
        }
    }

    fireEvent(name, data = {}) {
        if(!this.events[name]) return;
        try {

            for(const id of Object.keys(this.events[name])) {
                try {
                    this.events[name][id](data);
                } catch(e) {}
            }

        } catch(e) {}
    }

    removeEvent(name, id = null) {

    }

    on(name, id = null, callback) {
        this.events[name] = this.events[name] || {};
        if(!id) id = 'default';
        this.events[name][id] = callback;
    }


}
