<template>
    <div class="input">
        <div class="_header">
            <label class="label">{{ name || 'Label' }}</label>
            <span class="_error">{{ error }}</span>
        </div>

        <DropDownListVue class="input-field dropdown-input" @select="(option) => { $emit('select', option) }" @search="(search) => { $emit('search', search) }" :dynamic-search="dynamicSearch" :loading="loading" :placeholder="placeholder" v-model="content" :showAttribute="showAttribute" :selectAttribute="selectAttribute" :options="options"/>
        <div class="description" v-if="description">{{ description }}</div>
    </div>
</template>

<script>
import DropDownListVue from './DropDownList.vue';
    export default {
        components: {
            DropDownListVue
        },
        props: ['name', 'modelValue', 'error', 'placeholder', 'description', 'showAttribute', 'selectAttribute', 'options', 'dynamic-search', 'loading'],
        data() {
            return {
                content: this.modelValue
            }
        },
        methods: {
            
        },
        watch: {
            content(newValue, oldValue) {
                this.$emit('update:modelValue', this.content);
            },
            modelValue() {
                this.content = this.modelValue;
            }
        },
    }
</script>


<style lang="scss" scoped>
.dropdown-input {
    padding: 0 10px;
    max-width: 100%;
}
</style>