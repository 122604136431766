<template>
    <div class="message-group" :class="[{ 'company-message' : message.to }]">

        <div class="message" :class="[{ 'company-message' : message.to }]">
            <div class="message-text" v-html="formattedText"></div>
            <div class="message-details">
                <div class="message-time">{{ messageTime }}</div>
                <div v-if="message.to" class="icon">
                    <span v-if="message.seen"><i class="fa-solid fa-check-double"></i></span>
                    <span v-else><i class="fa-solid fa-check"></i></span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['message'],
        computed: {
            messageTime() {
                return moment(this.message.date).format("HH:mm")
            },
            formattedText() {
                return this.message.message.replace(/\n/g, "<br>")
            }
        }
    }
</script>

<style lang="scss" scoped>
.message-group {
    display: flex;
    justify-content: flex-start;
    &.company-message {
        justify-content: flex-end;
    }
}

.message {
    max-width: 80%;
    background: $themeColor1;
    border-radius: 9px;
    box-shadow: $boxShadow;
    padding: 7px 7px;
    border: 1px solid $borderColor;
    &.company-message {
        background: $themeColor2;
        color: $themeColor1;
    }
    .message-time {
        font-size: 10px;
    }
    .message-details {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 3px;
        .icon {
            font-size: 10px;
        }
    }
}
</style>