<template>
    <div class="file-storage-uploader">
        <div class="loading-bar" :style="[`width: ${uploading.percent}%`]" v-if="isLoading(['FileStorageUpload'])"></div>
        <div class="file-details text-overflow">
            <div v-if="isLoading(['FileStorageUpload'])">
                <div v-if="uploading.percent < 100">Uploading ({{ uploading.percent }}%)</div>
                <div v-if="uploading.percent >= 100">Verifying file...</div>
            </div>
            <div v-else>
                <span v-if="file && fileId"><a :href="file.location" target="_blank">{{ file.originalname }}</a></span>
                <span v-else>No file selected</span>
            </div>
        </div>
        <button @click="selectAndUploadFile" class="uploadButton" :disabled="isLoading(['FileStorageUpload'])">Select</button>
    </div>
</template>

<script>
import FilePicker from '../mixins/FilePicker';
    export default {
        mixins: [FilePicker],
        props: ['modelValue', 'selected'],
        data() {
            return {
                fileId: this.modelValue || null,
                file: this.selected || null,
                uploading: {
                    value: false,
                    percent: 0
                }
            }
        },
        mounted() {
            if(this.fileId) this.getFileDetails();
        },
        watch: {
            fileId() {
                this.$emit('update:modelValue', this.fileId);
            },
            modelValue() {
                this.fileId = this.modelValue || null;
            },
            selected() {
                this.file = this.selected || null;
            }
        },
        methods: {
            getFileDetails() {
                console.log('getting file details');
            },
            onUploadProgress(progressEvent) {
                let percent = Number(Number((progressEvent.loaded * 100) / progressEvent.total).toFixed(0));
                this.uploading.percent = percent;
            },
            selectAndUploadFile() {
                this.createFilePicker(null, async (file) => {

                    this.fileId = null;
                    this.file = null;

                    var data = new FormData();
                    data.append('file', file);

                    this.uploading.value = true;
                    this.uploading.percent = 0;
                    
                    await this.ajax('FileStorageUpload', {
                        url: `/UploadFile`,
                        method: 'POST',
                        data: data,
                        onUploadProgress: this.onUploadProgress
                    }, (err, body) => {
                        if(err) {
                            this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                            return;
                        }
                        if(body._id) {
                            this.file = body;
                            this.fileId = body._id;
                        }
                    });
                    this.uploading.value = false;

                });
            },
        },
    }
</script>

<style lang="scss" scoped>
.file-storage-uploader {
    width: 100%;
    height: 38px;
    border-radius: 7px;
    outline: none;
    padding: 0 10px;
    border: 1px solid $borderColor;
    background: $themeColor1;
    color: $text;
    font-size: 16px;
    font-size: 13px;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.02);
    display: grid;
    grid-template-columns: minmax(0, 1fr) 84px;
    column-gap: 20px;
    align-items: center;
    padding: 5px 5px 5px 10px;
    overflow: hidden;
    position: relative;
    .loading-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 3px;
        background: $themeColor2;
        width: 34%;
    }
}

.uploadButton {
    border: 1px solid $borderColor;
    border-radius: 8px;
    background: $themeColor1;
    color: $text;
    font-size: 10px;
    cursor: pointer;
    height: 100%;
    &:disabled {
        cursor: not-allowed;
    }
}
</style>