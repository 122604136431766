<template>
    <div class="chat-item">
        <div class="image" :style="`background-image: url('${chatImage}')`"></div>
        <div class="chat-content">
            <div class="name-group">
                <div class="driverName text-overflow">
                    <div class="name">{{ item.driver.name }} {{ item.driver.lastname }}</div>
                    <div class="companyName">{{ item.driver.carrier.companyName }}</div>
                </div>
                <div class="time-group">
                    <div v-if="item.lastMessage.to" class="seenIcon">
                        <span v-if="item.lastMessage.seen"><i class="fa-solid fa-check-double"></i></span>
                        <span v-else><i class="fa-solid fa-check"></i></span>
                    </div>
                    <div class="time-item">{{ messageTime }}</div>
                </div>
            </div>
            <div class="message text-overflow">
                <span v-if="item.lastMessage.to" style="color: rgba(0,0,0,0.4);">You:</span>
                <span :style="[!item.lastMessage.seen && item.lastMessage.from ? 'font-weight: 600' : '']">{{ item.lastMessage.message }}</span>
            </div>

            

            <div class="unreadCount" v-if="item.unreadCount > 0">{{ item.unreadCount }}</div>

        </div>
    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['item'],
        computed: {
            chatImage() {
                if(this.item.driver.image) return this.item.driver.image;
                return this.DEFAULTS.USER.IMAGE;
            },
            messageTime() {

                const date = moment(this.item.lastMessage.date);
                let val = null;
                if (moment().isSame(date, 'day')) {
                    val = date.format("HH:mm");
                } else if (moment().subtract(1, 'days').isSame(date, 'day')) {
                    val = 'Yesterday';
                } else {
                    val = date.format('D MMM');
                }

                return val
            }
        }
    }
</script>

<style lang="scss" scoped>
.chat-item {
    padding: 10px;
    display: grid;
    grid-template-columns: 44px minmax(0, 1fr);
    gap: 15px;
    border-bottom: 1px solid $borderColor;
    transition: ease 0.1s;
    cursor: pointer;
    user-select: none;
    &:hover {
        background: rgba(0,0,0,0.05);
    }
}

.image {
    width: 44px;
    height: 44px;
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.chat-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.time-group {
    display: flex;
    align-items: center;
    gap: 5px;
    .time-item {
        font-size: 10px;
    }
    .seenIcon {
        color: $themeColor2;
        font-size: 12px;
    }
}

.name-group {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    gap: 10px;
}

.driverName {
    display: flex;
    align-items: center;
    gap: 5px;
    .name {
        line-height: 14px;
        font-weight: 500;
        font-size: 14px;
    }
    .companyName {
        color: rgba(0,0,0,0.3);
        font-weight: 400;
        font-size: 12px;
    }
}

.carrierName {
    font-size: 12px;
}

.message {
    display: grid;
    align-items: start;
    grid-template-columns: auto minmax(0, 1fr);
    gap: 5px;
    font-size: 12px;
}

.unreadCount {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $themeColor2;
    padding: 2px 7px;
    font-size: 10px;
    color: $themeColor1;
    border-radius: 24px;
    line-height: 12px;
}


</style>