export default { 
    path: '/admin',
    name: 'Admin',
    component: function () { return import('../views/Admin/Main.vue') }, 
    children: [
        { 
            path: '',
            name: 'AdminPerformance',
            component: function () { return import('../views/Admin/Performance.vue') },
            meta: {
                permissions: [ 
                    { name: 'ADMIN_ONLY' } 
                ]
            }
        },
        { 
            path: 'roles', 
            name: 'AdminRoles', 
            component: function () { return import('../views/Admin/Roles.vue') },
            meta: {
                permissions: [ 
                    { name: 'Roles' } 
                ]
            }
        },
        { 
            path: 'users', 
            name: 'AdminUsers', 
            component: function () { return import('../views/Admin/Users.vue') },
            meta: {
                permissions: [ 
                    { name: 'Users' } 
                ]
            }
        },
        { 
            path: 'carriers',
            name: 'AdminCarriers',
            component: function () { return import('../views/Admin/Carriers.vue') },
            meta: {
                permissions: [ 
                    { name: 'Carriers' } 
                ]
            }
        },
        { 
            path: 'carrier/:id/drivers',
            name: 'AdminCarrierDrivers',
            component: function () { return import('../views/Admin/CarriersDrivers.vue') },
            meta: {
                permissions: [ 
                    { name: 'Carriers' } 
                ]
            }
        },
        { 
            path: 'salary',
            name: 'AdminSalary',
            component: function () { return import('../views/Admin/Salary.vue') },
            meta: {
                permissions: [ 
                    { name: 'Users' } 
                ]
            }
        },
        { 
            path: 'invoices',
            name: 'AdminInvoices',
            component: function () { return import('../views/Admin/Invoices.vue') },
            meta: {
                permissions: [ 
                    { name: 'Invoices' } 
                ]
            }
        },
        { 
            path: 'referrals',
            name: 'AdminReferrals',
            component: function () { return import('../views/Admin/Referrals/Main.vue') },
            meta: {
                permissions: [ 
                    { name: 'ADMIN_ONLY' } 
                ]
            },
            children: [
                { 
                    path: '',
                    name: 'AdminReferralsMain',
                    component: function () { return import('../views/Admin/Referrals/Users.vue') },
                },
                { 
                    path: 'payments',
                    name: 'AdminReferralsPayments',
                    component: function () { return import('../views/Admin/Referrals/Payments.vue') },
                }
            ]
        }
    ]
}