import AppMap from './components/AppMap'
import AppMapDirections from './components/AppMapDirections'
import AppMapMarker from './components/AppMapMarker'

export default {
    install: (app, options) => {
        app.component('AppMap', AppMap);
        app.component('AppMapDirections', AppMapDirections);
        app.component('AppMapMarker', AppMapMarker);
    }
}