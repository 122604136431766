<template>
    <div>
        <input class="search" v-model.lazy="search" :placeholder="placeholder || 'Search'">
    </div>
</template>

<script>
    export default {
        props: ['modelValue', 'placeholder'],
        data() {
            return {
                search: this.modelValue || ''
            }
        },
        watch: {
            modelValue() {
                this.search = this.modelValue || '';
            },
            search() {
                this.$emit('update:modelValue', this.search);
            }
        }
    }
</script>

<style lang="scss" scoped>
.search {
    height: 44px;
    background: $themeColor1;
    border-radius: 10px;
    width: 100%;
    border: 0;
    padding: 0 14px;
    outline: none;
    border: 1px solid $borderColor;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.02);
    &:focus {
        outline: auto;
        outline-color: $themeColor2;
        outline-width: 1px;
    }
}
</style>