import ChatButton from './components/ChatButton'
import ChatButtonDropDown from './components/ChatButtonDropdown'

export default {
    install: (app, options) => {


        let component = {
            components: {
                ChatButton
            },
            mounted() {
                app.config.globalProperties.$Chat = this.$refs['ChatButton'];
            },
            template: `<ChatButton ref="ChatButton"/>`
        }

        // register components here
        app.component('ChatBase', component);

        let componentButton = {
            components: {
                ChatButtonDropDown
            },
            mounted() {
                app.config.globalProperties.$Chat = this.$refs['ChatButton'];
            },
            template: `<ChatButtonDropDown ref="ChatButton"/>`
        }

        // register components here
        app.component('ChatBaseDropDown', componentButton);

    }
}