<template>
    <div>
        <div @click="switchCheckbox" class="checkbox" :class="{ 'active' : selected === true || selected.includes(val) }">
            <div class="check" ><i class="fa-solid fa-check"></i></div>
        </div>
        <input type="checkbox" ref="checkbox" :value="val" v-model="selected" hidden>
    </div>
</template>

<script>
    export default {
        props: ['modelValue', 'val'],
        data() {
            return {
                selected: this.modelValue || []
            }
        },
        methods: {
            switchCheckbox() {
                let checkbox = this.$refs.checkbox;
                checkbox.click();
            }
        },
        mounted() {
            this.$emit('update:modelValue', this.selected)
        },
        watch: {
            selected() {
                this.$emit('update:modelValue', this.selected)
            },
            modelValue() {
                this.selected = this.modelValue;
            }
        }
    }
</script>

<style lang="scss" scoped>
.checkbox {
    width: 21px;
    height: 21px;
    background: $bg;
    transition: ease 0.3s;
    box-shadow: inset 0px 0px 3px 2px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    position: relative;
    cursor: pointer;
    user-select: none;
    .check {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        opacity: 0;
        font-size: 12px;
    }
    &.active {
        background: $themeColor2;
        .check {
            opacity: 1;
        }
    }
}
</style>