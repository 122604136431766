import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(function (config) {
    let token = localStorage.getItem('token');
    if(token) config.headers.authorization = token;

    let referralToken = localStorage.getItem('referral-token');
    if(referralToken) config.headers['referral-auth-token'] = `Bearer ${referralToken}`;


    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});