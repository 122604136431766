export default {
    methods: {
        async chat_get_list(callback) {
            await this.ajax('Chat_GetListOfChats', {
                method: 'GET',
                url: '/chats'
            }, callback)
        },
        async chat_get_messages(chatId, offset = 0, callback) {
            await this.ajax('Chat_GetListOfMessages', {
                method: 'GET',
                url: `/chats/${chatId}/${offset}`
            }, callback)
        },
        async chat_search_drivers(query = '', callback) {
            await this.ajax('Chat_GetListOfPossibleChats', {
                method: 'GET',
                url: `/chats/drivers/search?query=${query}`
            }, callback)
        },
        async chat_get_chat_info(chatId, callback) {
            await this.ajax('Chat_GetChatInfo', {
                method: 'GET',
                url: `/chats/chat/${chatId}`
            }, callback)
        }
    },
}