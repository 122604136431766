import { createStore, Store } from 'vuex'
import axios from 'axios';

export default createStore({
    state: {
        user: null,
        token: null,
        axiosLoaders: []
    },
    getters: {
        user: (state) => {
            return state.user;
        },
        token: (state) => {
            return state.token;
        },
        axiosLoaders: (state) => {
            return state.axiosLoaders;
        }
    },
    mutations: {
        token: (state, payload) => {
            state.token = payload;
        },
        userLogout: (state) => {
            state.user = null;
            state.token = null;
            localStorage.removeItem('token');
            location.reload();
        },
        user: (state, payload) => {
            state.user = {
                ...payload,
                getUserDetails: async function() {
                    return await new Promise(resolve => {
                        axios.get('/user')
                        .then(({ data }) => {
                            resolve(data);
                        })
                        .catch(e => {
                            resolve(null);
                        });
                    });
                },
                isAuthorized: function (permissionName, permissionAction) {
                    if(this.isAdmin) return true;
                    if(!this.role) return false;
                    if(!this.role.permissions) return false;
                    if(!this.role.permissions[permissionName]) return false;
                    if(permissionAction != null) {
                        if(this.role.permissions[permissionName].includes(permissionAction)) return true;
                        return false;
                    }
                    return true;
                },
                changeImage: function(image) {
                    this.image = image;
                },
                logout: function() {
                    state.user = null;
                    state.token = null;
                    localStorage.removeItem('token');
                    location.reload();
                }
            }
        }
    },
    actions: {

    },
    modules: {

    }
})