<template>
    <div>
        <div class="period-picker">
            
            <button @click="shiftWeeksBy(-1)"><i class="fa-solid fa-arrow-left"></i></button>
            <div class="weeks">
                <div class="week" :key="key" :class="[{ 'active': week.isActive }]" @click="selectWeek(week)" v-for="(week, key) of weeks">
                    <div>{{  week.startFormatted  }} - {{  week.endFormatted  }}</div>
                    <div class="current-week" v-if="week.isCurrentWeek">Current week</div>
                </div>
            </div>
            <button @click="shiftWeeksBy(1)"><i class="fa-solid fa-arrow-right"></i></button>
            <div class="reset-btn" v-if="isJumpToEnabled || isJumpToCurrentPeriodEnabled">
                <span>Jump to:&nbsp;</span>
                <a href="" @click.prevent="jumpToSelection" v-if="isJumpToEnabled">Selection</a>
                <span v-if="isJumpToEnabled && isJumpToCurrentPeriodEnabled">&nbsp;|&nbsp;</span>
                <a href="" @click.prevent="resetShift" v-if="isJumpToCurrentPeriodEnabled">Current period</a>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
    export default {
        props: ['modelValue'],
        data() {
            return {
                selected: this.modelValue || null,
                before: 2,
                after: 2,
                shift: 0
            }
        },
        computed: {
            isJumpToEnabled() {
                return this.shiftNeededToCenter <= (this.shift-3) || this.shiftNeededToCenter >= (this.shift+3);
            },
            isJumpToCurrentPeriodEnabled() {
                return (this.shift >= 3 || this.shift <= -3);
            },
            weeks() {
                if(!this.selected) return [];
                let todaysDate = moment.utc();
                let weeks = [];
                let total = this.before + this.after + 1;
                let date = moment.utc().startOf('isoWeek').add(this.shift, 'weeks').add(this.after, 'weeks');
                for(let i = 0; i < total; i++) {
                    let start = date.clone().subtract(i, 'weeks');
                    let end = start.clone().endOf('isoWeek');
                    let isCurrentWeek = todaysDate.isSameOrAfter(start) && todaysDate.isSameOrBefore(end);
                    let isActive = (start.isSame(moment.utc(this.selected.start)) && end.isSame(moment.utc(this.selected.end)));
                    weeks.push({
                        start: start.toDate(),
                        end: end.toDate(),
                        isCurrentWeek: isCurrentWeek,
                        startFormatted: start.format("D MMM YY"),
                        endFormatted: end.format("D MMM YY"),
                        isActive
                    });
                }
                weeks.reverse();
                return weeks;
            },
            shiftNeededToCenter() {
                try {
                    let currentDate = moment.utc().startOf('isoWeek');
                    let shift = moment.duration(moment.utc(this.selected.start).diff(currentDate)).asWeeks();
                    return shift;
                } catch(e) {
                    return 0;
                }
            }
        },
        methods: {
            jumpToSelection() {
                this.shift = this.shiftNeededToCenter;
            },
            resetShift() {
                this.shift = 0;
            },
            shiftWeeksBy(val) {
                this.shift += val;
            },
            selectWeek(week) {
                this.selected = {
                    start: week.start,
                    end: week.end
                }
            },
            setupDefault() {
                try {
                    let date = moment.utc(this.selected.start);
                    if(!date.isValid()) throw new Error();
                    date.startOf('isoWeek');
                    let end = date.clone().endOf('isoWeek');
                    this.selected = {
                        start: date.toDate(),
                        end: end.toDate()
                    }
                } catch(e) {
                    let date = moment.utc().startOf('isoWeek');
                    let endDate = date.clone().endOf('isoWeek');
                    this.selected = {
                        start: date.toDate(),
                        end: endDate.toDate()
                    }
                }

                this.shift = this.shiftNeededToCenter;
            }
        },
        mounted() {
            this.setupDefault();
        },
        watch: {
            modelValue(to, from) {
                this.selected = this.modelValue || null;
                if(!from) this.setupDefault();
            },
            selected() {
                this.$emit('update:modelValue', this.selected);
            }
        }
    }
</script>

<style lang="scss" scoped>

.period-picker {
    height: 60px;
    display: grid;
    grid-template-columns: 60px minmax(0, 1fr) 60px;
    gap: 20px;
    position: relative;
    .reset-btn {
        position: absolute;
        bottom: 100%;
        right: 0;
        transform: translateY(-10px);
    }
    button {
        background: $themeColor1;
        border-radius: 13px;
        box-shadow: $boxShadow;
        border: 0;
        font-size: 14px;
        cursor: pointer;
        transition: ease 0.3s;
        &:hover {
            color: $themeColor2;
        }
    }
}

.weeks {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 20px;
    height: 100%;
    .week {
        height: 100%;
        display: grid;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
        background: $themeColor1;
        border-radius: 13px;
        box-shadow: $boxShadow;
        transition: ease 0.3s;
        cursor: pointer;
        user-select: none;
        position: relative;
        border: 1px solid transparent;
        .current-week {
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 50%);
            padding: 4px 7px;
            background: $themeColor2;
            font-size: 10px;
            color: $themeColor1;
            border-radius: 7px;
        }

        &:hover {
            color: $themeColor2;
            // transform: scale(1.02);
        }
        &.active {
            color: $themeColor2;
            border: 1px solid $themeColor2;
        }
    }
}
</style>