<template>
    <div class="action-bar">
        <h1 class="title">{{ title }}</h1>
        <div class="action"><slot></slot></div>
    </div>
</template>

<script>
    export default {
        props: ['title']
    }
</script>

<style lang="scss" scoped>
.action-bar {
    display: grid;
    align-items: center;
    grid-template-columns: auto minmax(0, 1fr);
    width: 100%;
    gap: 30px;
    .title {
        font-weight: 300;
        font-size: 22px;
    }
    .action {
        width: 100%;
    }
}
</style>