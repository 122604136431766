import { createVNode, defineComponent, render } from "vue/dist/vue.esm-bundler";
import { shallowRef } from "vue";
import { v4 as uuidv4 } from 'uuid'

import Toaster from './Toaster'

export default {
    install: (app, options) => {


        let component = {
            components: {
                Toaster,
            },
            data() {
                return {
                    toasterRef: null
                }
            },
            mounted() {
                this.toasterRef = this.$refs['toaster-holder'];
                app.config.globalProperties.$Toast = this.toasterRef;
            },
            template: `<Toaster ref="toaster-holder"/>`,
        }

        app.component('Toast', component);
    }
  }