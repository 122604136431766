<template>
    <div class="alert-body">
        {{ text }}
    </div>
</template>

<script>
    export default {
        props: ['text']
    }
</script>

<style lang="scss" scoped>
.alert-body {
    padding: 30px 20px 20px 20px;
}
</style>