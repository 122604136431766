<template>
    <div class="no-data-message">
        <div class="icon"><i :class="icon || 'fa-solid fa-magnifying-glass'"></i></div>
        <div class="message">{{ text || 'No Data' }}</div>
    </div>
</template>

<script>
    export default {
        props: ['icon', 'text']
    }
</script>

<style lang="scss" scoped>
.no-data-message {
    display: grid;
    padding: 100px 30px;
    align-items: center;
    justify-items: center;
    row-gap: 20px;
    color: $textShade;
    .icon {
        font-size: 30px;
        color: $bgShade;
    }
}
</style>