<template>
    <div class="table-view">
        <div class="table-view-row head" :style="`grid-template-columns: ${size.join(' ')}`">
            <div class="col text-overflow" :key="key" v-for="(item, key) of head">{{ item.name }}</div>
        </div>
        <slot></slot>
        <div class="loader" v-if="loading"></div>
    </div>
</template>

<script>
    export default {
        props: {
            head: { type: Array, required: true },
            size: { type: Array, required: true },
            loading: { type: Boolean, required: false },
        }
    }
</script>

<style lang="scss">
.table-view {
    position: relative;
    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f6f8fb82;
        z-index: 2;
    }
    .table-view-row {
        display: grid;
        column-gap: 10px;
        align-items: center;
        padding: 10px;
        transition: ease 0.3s;
        border-radius: 10px;
        font-weight: 400;
        font-size: 12px;
        min-height: 44px;
        width: 100%;
        &.head {
            height: auto;
            min-height: 44px;
            border-bottom: 1px solid $borderColor;
            border-radius: 0;
            padding: 20px 10px;
            margin-bottom: 20px;
            .col {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
            }
        }
        &.row-hover {
            &:hover {
                background: $themeColor1;
                box-shadow: $boxShadow;
            }
        }
    }
}
</style>