<template>
  <LMarker
    :lat-lng="latLng"
    @ready="markerReady"
    :icon="markerIcon"
    v-if="markerIcon"
    ><LPopup><slot></slot></LPopup
  ></LMarker>
  <LMarker :lat-lng="latLng" @ready="markerReady" v-else
    ><LPopup><slot></slot></LPopup
  ></LMarker>
</template>

<script>
import { LMarker, LPopup } from "@vue-leaflet/vue-leaflet";

import { icon } from "leaflet";

import locationMarker from "../images/location-marker.png";
import stopMarker from "../images/stop-marker.png";

export default {
  components: { LMarker, LPopup },
  props: ["latLng", "type"],
  data() {
    return {};
  },
  computed: {
    markerIcon() {
      try {
        if (!this.type) throw Error();
        if (this.type === "CURRENT_LOCATION_MARKER") {
          return icon({
            iconUrl: locationMarker,
            iconSize: [24, 24],
            iconAnchor: [12, 12],
          });
        } else if (this.type === "STOP_MARKER") {
          let size = 10;
          return icon({
            iconUrl: stopMarker,
            iconSize: [size, size],
            iconAnchor: [size / 2, size / 2],
          });
        } else {
          throw Error();
        }
      } catch (e) {
        return null;
      }
    },
  },
  methods: {
    markerReady() {
      this.$emit("onReady", this.latLng);
    },
  },
};
</script>

<style lang="scss" scoped></style>
