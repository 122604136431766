<template>
    <div class="input">
        <div class="_header">
            <label class="label">{{ name || 'Label' }}</label>
            <span class="_error">{{ error }}</span>
        </div>
        <FileStorageUploader v-model="fileId" :selected="selected"/>
        <div class="description" v-if="description">{{ description }}</div>
    </div>
</template>

<script>
    export default {
        props: ['modelValue', 'name', 'description', 'error', 'selected'],
        data() {
            return {
                fileId: this.modelValue || null
            }
        },
        watch: {
            modelValue() {
                this.fileId = this.modelValue || null;
            },
            fileId() {
                this.$emit('update:modelValue', this.fileId);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>