<template>
    <div class="table-view-row row-hover" :class="{ 'menu-shown' : menuShown }" :style="`grid-template-columns: ${size.join(' ')}`">
        <slot></slot>
        <div v-if="menu"><DropDownMenuButton @show="menuShown = true" @hide="menuShown = false" :options="menu" v-on="parentEvents"/></div>
    </div>
</template>

<script>
    export default {
        props: {
            size: { type: Array, required: true },
            menu: { type: Array },
        },
        data() {
            return {
                menuShown: false
            }
        },
        computed: {
            parentEvents() {
                let events = {}
                if(this.menu) {
                    for(const item of this.menu) {
                        if(!item.emit) continue;
                        events[item.emit] = () => {
                            this.$emit(item.emit);
                        }
                    }
                }
                return events;
            }
        },
        methods: {
            eventEmitted(ev) {
                console.log(ev);
            }
        },
        mounted() {
            
        }
    }
</script>

<style lang="scss" scoped>
.table-view-row {
    position: relative;
    &.menu-shown {
        z-index: 1;
    }
}
</style>