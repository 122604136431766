<template>
    <div style="position: relative;" v-click-outside="() => { shown = false; }">
        <button @click="shown = !shown" class="nav-btn" :class="{ 'active' : shown }">
            <span><i class="fa-solid fa-comment"></i></span>
            <Transition name="bounce">
                <span class="badge" v-if="numberOfUnreadChats > 0">{{ numberOfUnreadChats }}</span>
            </Transition>
        </button>

        <Transition name="slide-fade">
            <div v-if="shown">
                <div class="chat-button" :class="{ 'shown' : shown && selectedChat }" >

                    <div class="chat-list-group">

                        <div class="chat-list-group-title">
                            <div class="title-group">
                                <button v-if="selectedChat !== null" class="chat-back-btn" @click.stop="selectedChat = null">Back</button>
                                <div class="title">

                                    <div v-if="selectedChatInfo">
                                        <div>{{ selectedChatInfo.name }} {{ selectedChatInfo.lastname }}</div>
                                        <div style="font-weight: 400; font-size: 12px;" v-if="selectedChatInfo.carrier">{{ selectedChatInfo.carrier.companyName }}</div>
                                    </div>
                                    <div v-else>{{ chatName }}</div>

                                </div>
                            </div>

                            <button class="chat-back-btn" @click.stop="soundOn = !soundOn">
                                <span v-if="soundOn"><i class="fa-solid fa-volume-high"></i></span>
                                <span v-else><i class="fa-solid fa-volume-xmark"></i></span>
                            </button>
                        </div>

                        <div>
                            <ChatThread :chatId="selectedChat" v-if="selectedChat !== null" :messages="chatMessages" @messageSent="addNewMessage"/>
                            <div class="chat-list-scroll" v-show="selectedChat === null">
                                <div class="search-group">
                                    <input placeholder="Search" class="search-input" v-model="search"/>
                                </div>
                                <div v-if="search === ''">
                                    <div v-if="sortedChats.length > 0">
                                        <ChatListItem :key="item._id" @click="selectChat(item._id, item)" :item="item" v-for="item of sortedChats"/>
                                    </div>
                                    <NoDataMessage v-else text="No chats yet" icon="fa-solid fa-comments"/>
                                </div>
                                <div v-else>
                                    <div v-if="searchResults.length > 0">
                                        <ChatListSearchItem :key="item._id" @click="selectChat(item._id, item); search = ''" :item="item" v-for="item of searchResults"/>
                                    </div>
                                    <NoDataMessage v-else text="No results found."/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Transition>

    </div>
</template>

<script>

import ChatListItem from './ChatListItem.vue';
import ChatListSearchItem from './ChatListSearchItem.vue';

import ChatJS from '../mixins/index'
import ChatThread from './ChatThread.vue';
import Input from '../../../components/default/Input.vue';

    export default {
        mixins: [ChatJS],
        components: {
            ChatListItem,
            ChatThread,
            Input,
            ChatListSearchItem
        },
        data() {
            return {
                chats: [],
                selectedChat: null,
                chatsStorage: {},
                shown: false,
                soundOn: true,
                audio: new Audio('/sound/new_message2.mp3'),
                search: '',
                searchResults: [],
                selectedChatInfo: null
            }
        },
        watch: {
            search() {
                this.searchDrivers();
            },
            selectedChat() {
                this.selectedChatInfo = null;
                this.getChatInfo();
            }
        },
        computed: {
            chatName() {
                if(!this.selectedChat) return `Chats ${this.numberOfUnreadChats > 0 ? '(' + this.numberOfUnreadChats + ')' : ''}`;
                return 'Chat';
            },
            chatMessages() {
                if(!this.selectedChat) return null;
                return this.chatsStorage[this.selectedChat] || {};
            },
            sortedChats() {
                return this.chats.sort((a,b) => (a.lastMessage.date > b.lastMessage.date) ? -1 : ((b.lastMessage.date > a.lastMessage.date) ? 1 : 0))
            },
            numberOfUnreadChats() {
                let count = 0;

                for(const chat of this.chats) {
                    if(chat.lastMessage.from && !chat.lastMessage.seen) count++;
                }

                return count;
            }
        },
        methods: {
            getChatInfo() {
                if(!this.selectedChat) return;
                this.chat_get_chat_info(this.selectedChat, (err, body) => {
                    if(!err) {
                        this.selectedChatInfo = body;
                    }
                });
            },
            getChats() {
                this.chat_get_list((err, body) => {
                    if(!err) {
                        this.chats = body;
                    }
                });
            },
            selectChat(chatId) {
                this.shown = true;
                this.selectedChat = chatId;
                this.getSelectedChatMessages(chatId);
            },
            getSelectedChatMessages(chatId, offset = 0) {
                this.chat_get_messages(chatId, offset, (err, body) => {
                    if(!err) {
                        this.addMessagesToChat(chatId, body, offset);
                    }
                });
            },
            addNewMessage({ chatId, message }) {
                this.addMessagesToChat(chatId, { messages: [message] }, -1);
            },
            addMessagesToChat(chatId, data, offset) {

                try {

                    this.chatsStorage[chatId] = this.chatsStorage[chatId] || { hasMore: false, count: 0, messages: [] }
                    let messages = [...this.chatsStorage[chatId].messages];

                    if(offset === 0) {
                        this.chatsStorage[chatId].hasMore = data.hasMore;
                        messages = data.messages;
                    } else if(offset === -1) {

                        for (const message of data.messages) {
                            let isAlreadyThere = messages.some(item => item._id === message._id);
                            if (!isAlreadyThere) messages.push(message);
                        }
                    } else if (offset >= 1) {
                        this.chatsStorage[chatId].hasMore = data.hasMore;
                        try {
                            let checkedArray = data.messages.filter(message => {
                                let isThere = messages.some(item => item._id === message._id);
                                return !isThere;
                            });
                            return messages.concat(checkedArray);
                        } catch(e) {}
                    }

                    this.chatsStorage[chatId].messages = messages;

                } catch(e) {
                    console.log(e)
                }

                    

            },
            onChatUpdate(chat) {
                let indexOfChat = this.chats.findIndex(item => item._id === chat._id);
                if(indexOfChat != -1) this.chats[indexOfChat] = chat;
                else this.chats.unshift(chat);
            },
            onMessageSeen(message) {
                let chatId = message.chatId;
                let chat = this.chats.find(item => item._id === chatId);
                if(chat && this.chatsStorage[chatId]) {
                    let indexOfMessage = this.chatsStorage[chatId].messages.findIndex(item => item._id === message._id);
                    if(indexOfMessage !== -1) this.chatsStorage[chatId].messages[indexOfMessage] = message;
                }
            },
            searchDrivers() {
                this.chat_search_drivers(this.search, (err, body) => {
                    if(err) {
                        this.searchResults = []
                    } else {
                        this.searchResults = body;
                    }
                });
            }
        },
        mounted() {
            this.getChats();
            this.$WebSocket.on('/dispatch/CarrierChat/message', 'ChatButtonComponent', (data) => {
                this.addMessagesToChat(data.chatId, { messages: [data] }, -1);

                if(data.from && this.soundOn) {
                    try {
                        this.audio.volume = 0.7;
                        this.audio.play();
                    } catch(e) {}
                }

            });
            this.$WebSocket.on('/dispatch/CarrierChat/chat', 'ChatButtonComponent', this.onChatUpdate);
            this.$WebSocket.on('/dispatch/CarrierChat/message/seen', 'ChatButtonComponent', this.onMessageSeen);
        }
    }
</script>

<style lang="scss" scoped>
.chat-button {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99;
    width: 420px;
    transform: translateY(20px);
    box-shadow: $boxShadow;
    &.shown {
        z-index: 1002;
    }
}

.chat-base-title {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 10px;
}

.chat-list-group-title {
    padding: 10px;
    border-bottom: 1px solid $borderColor;
    background: $themeColor1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
        font-weight: 600;
        font-size: 16px;
    }
    .title-group {
        display: flex;
        align-items: center;
        gap: 15px;
    }
}


.chat-list-group {
    width: 100%;
    background: $themeColor1;
    overflow: hidden;
    border-radius: 9px;
    border: 1px solid $borderColor;
}

.chat-list-scroll {
    height: 350px;
    overflow-y: auto;
    position: relative;
    .search-group {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $borderColor;
        background: $themeColor1;
        z-index: 2;
        .search-input {
            width: 100%;
            height: 34px;
            border: 0;
            background: transparent;
            padding: 0 10px;
            outline: none;
        }
    }
}

.chat-back-btn {
    border: 0;
    padding: 0;
    height: 28px;
    background: transparent;
    cursor: pointer;
    color: $themeColor2;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translate(25%, -25%) scale(0);
  }
  50% {
    transform: translate(25%, -25%) scale(1.25);
  }
  100% {
    transform: translate(25%, -25%) scale(1);
  }
}
</style>