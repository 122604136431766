import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios';
import { createPinia } from 'pinia'

export const pinia = createPinia();

let app = createApp(App);

app.use(pinia);

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)


import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();


// global components
import Button from './components/default/Button'
app.component('Button', Button);

import Input from './components/default/Input'
app.component('Input', Input);

import Switch from './components/default/Switch'
app.component('Switch', Switch);

import Checkbox from './components/default/Checkbox'
app.component('Checkbox', Checkbox);

import DropDownCheckbox from './components/default/DropDownCheckbox'
app.component('DropDownCheckbox', DropDownCheckbox);

import InputDropDownCheckbox from './components/default/InputDropDownCheckbox'
app.component('InputDropDownCheckbox', InputDropDownCheckbox);

import DropDownList from './components/default/DropDownList'
app.component('DropDownList', DropDownList);

import InputDropDownList from './components/default/InputDropDownList'
app.component('InputDropDownList', InputDropDownList);

// v-calendar
import 'v-calendar/dist/style.css';
import { SetupCalendar, DatePicker } from 'v-calendar';
app.use(SetupCalendar, {})
app.component('DatePicker', DatePicker);

import DropDownDatePicker from './components/default/DropDownDatePicker'
app.component('DropDownDatePicker', DropDownDatePicker);

import DropDownDateRangePicker from './components/default/DropDownDateRangePicker'
app.component('DropDownDateRangePicker', DropDownDateRangePicker);

import InputDropDownDatepicker from './components/default/InputDropDownDatepicker'
app.component('InputDropDownDatepicker', InputDropDownDatepicker);

import ActionBar from './components/ActionBar'
app.component('ActionBar', ActionBar);

import TableView from './components/TableView'
app.component('TableView', TableView);

import TableRow from './components/TableViewRow'
app.component('TableRow', TableRow);

import PageSearchInput from './components/PageSearchInput'
app.component('PageSearchInput', PageSearchInput);

import Spinner from './components/Spinner'
app.component('Spinner', Spinner);

import NoDataMessage from './components/NoDataMessage'
app.component('NoDataMessage', NoDataMessage);

import DropDownMenuButton from './components/default/DropDownMenuButton'
app.component('DropDownMenuButton', DropDownMenuButton);

import FileStorageUploader from './components/FileStorageUploader.vue'
app.component('FileStorageUploader', FileStorageUploader);

import InputFileStorageUploader from './components/default/InputFileStorageUploader.vue'
app.component('InputFileStorageUploader', InputFileStorageUploader);

import PeriodPicker from './components/Salary/PeriodPicker.vue'
app.component('PeriodPicker', PeriodPicker);

// use
import vClickOutside from "click-outside-vue3"
app.use(vClickOutside);

import ModalManager from './plugins/ModalManager';
app.use(ModalManager);

import AppWebSocket from './plugins/AppWebSocket/index';
app.use(AppWebSocket);

import CarrierChat from './plugins/CarrierChat';
app.use(CarrierChat);

import { DynamicScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
app.component('DynamicScroller', DynamicScroller)

import ResizeTextarea from 'resize-textarea-vue3'
app.use(ResizeTextarea)

import FloatingVue from 'floating-vue'
app.use(FloatingVue)
import 'floating-vue/dist/style.css'

import Toaster from './plugins/Toaster';
app.use(Toaster);

import Maps from './plugins/Maps';
app.use(Maps);

// mixins
import AxiosLoader from './mixins/AxiosLoader'
app.mixin(AxiosLoader);

import Vars from './vars'
app.mixin(Vars);



app.use(store).use(router).mount('#app')
